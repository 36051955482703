import './App.css';
import { createBrowserRouter, Outlet, RouterProvider, useOutletContext } from 'react-router-dom';
import { AppStateWrapper } from './AppState';
import { SiteNavBar } from './components/SiteNavBar';
import { useEffect, useState } from 'react';
import { Alert, AlertProps, Snackbar } from '@mui/material';

export const router = createBrowserRouter([{
	path: '/',
	element: <Root />,
	children: [
		{
			index: true, // C'est la racine
			lazy: async () => {
				let Accueil = await import('./pages/Accueil');
				return { Component: Accueil.default };
			}
		},
		{
			index: false,
			element: <Container />,
			children: [
				{
					path: 'qrcode/:order_ref',
					lazy: async () => {
						let { QRDisplayPage } = await import('./pages/QRCode');
						return { Component: QRDisplayPage };
					}
				},
				{
					path: 'scan',
					lazy: async () => {
						let { ScannerPage } = await import('./pages/Scanner');
						return { Component: ScannerPage };
					}
				},
				{
					path: 'scan/:order_ref',
					lazy: async () => {
						let { QRDisplayPage } = await import('./pages/Scanner');
						return { Component: QRDisplayPage };
					}
				},
				{
					path: 'annonces',
					lazy: async () => {
						let Announces  = await import('./pages/Annonces');
						return { Component: Announces.default };
					}
				},
				{
					path: 'agenda',
					lazy: async () => {
						let  Agenda  = await import('./pages/Agenda');
						return { Component: Agenda.default };
					}
				},
				{
					path: 'bde',
					lazy: async () => {
						let PageBDE = await import('./pages/BDE');
						return { Component: PageBDE.default };
					}
				},
				{
                    path: 'rec',
                    lazy: async () => {
                        let PageREC = await import('./pages/REC');
                        return { Component: PageREC.default };
                    }
                },

				{
					path: 'assosclubs',
					lazy: async () => {
						let AssosClubs = await import('./pages/AssosClubs');
						return { Component: AssosClubs.default };
					}
				},
				{
					path: 'shop',
					lazy: async () => {
						let { ArticleList } = await import('./pages/Shop');
						return { Component: ArticleList };
					}
				},
				{
					path: 'shop/cotisations',
					lazy: async () => {
						let { CotisationList } = await import('./pages/Shop');
						return { Component: CotisationList };
					}
				},
				{
					path: 'shop/panier',
					lazy: async () => {
						let { PageMyArticles } = await import('./pages/Shop');
						return { Component: PageMyArticles };
					}
				},
				{
					path: 'mon_compte',
					lazy: async () => {
						let PageMonCompte = await import('./pages/PageMonCompte');
						return { Component: PageMonCompte.default };
					}
				},
				{
					path: 'elections',
					lazy: async () => {
						let { Elections } = await import('./pages/Elections');
						return { Component: Elections };
					}
				},
				{
					path: 'admin',
					lazy: async () => {
						let { UsersList } = await import('./pages/Admin');
						return { Component: UsersList };
					}
				},
				{
					path: 'admin/cotisations',
					lazy: async () => {
						let { CotisationsList } = await import('./pages/Admin');
						return { Component: CotisationsList };
					}
				},
				{
					path: 'admin/blacklist',
					lazy: async () => {
						let { BlacklistData } = await import('./pages/Admin');
						return { Component: BlacklistData };
					}
				},
				{
					path: 'pixelwar',
					lazy: async () => {
						let { RplacePage } = await import('./pages/Rplace');
						return { Component: RplacePage };
					}
				},
			]
		},
		//pages sans container
		{
			index: false,
			path: 'archives',
			lazy: async () => {
				let { Archive } = await import('./pages/Archives');
				return { Component: Archive };
			}
		}
	]
},
{
	index: false,
	path: 'Wei',
	lazy: async () => {
		let { Wei } = await import('./pages/WEI');
		return { Component: Wei };
	}
},
{
	index: false,
	path: 'Wei-en',
	lazy: async () => {
		let { Wei } = await import('./pages/WEI_EN');
		return { Component: Wei };
	}
},
]
);

function Container(props: any) {
	return (
		<div className='container'>
			<Outlet context={useOutletContext<RootMessage>()}/>
		</div>
	);
}

export interface RootMessage {
	onError: (msg: string) => void
	onSuccess: (msg: string) => void
}

function Root() {
	/* contient les informations necessaire à la création d'une alert */
	const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
	const handleCloseSnackbar = () => setSnackbar(null);
	
	useEffect(() => {
		const setSize = () => document.body.style.minHeight = `${window.innerHeight}px`;

		document.body.style.minHeight = `${window.innerHeight}px`;
		window.addEventListener('resize', setSize);
		return () => {
			window.removeEventListener('resize', setSize);
			document.body.style.minHeight = `auto`;
		}
	}, []);

	const handleError = (msg: string) => {
		setSnackbar({children: msg, severity: 'error'});
	}

	const handleSuccess = (msg: string) => {
		setSnackbar({children: msg, severity: 'success'});
	}

	return <>
		<SiteNavBar />
		<Outlet context={{onError: handleError, onSuccess: handleSuccess}}/>
		{!!snackbar && (
			<Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
				<Alert {...snackbar} onClose={handleCloseSnackbar} />
			</Snackbar>
		)}
	</>;
}

function App() {
	return <AppStateWrapper>
		<RouterProvider router={router} />
	</AppStateWrapper>;
}

export default App
