import React, { useEffect, useState } from "react";
import { Api } from "./utils/Api";
import { User } from "../../types";

export interface State {
	logged: boolean,
	user?: User,
	rplace?: string
}

// https://reactjs.org/docs/context.html
export const AppStateContext = React.createContext({ logged: false } as State);
export let GLOBAL_setAppState = (e: any) => {
	console.error("GLOBAL_setAppState not set");
	console.error(e);
};

export function AppStateWrapper({ children }: { children: any }) {

	const [appState, setAppState] = useState({ logged: false } as State);

	GLOBAL_setAppState = setAppState;

	useEffect(() => {
		Api.refreshState();
	}, []);

	return <AppStateContext.Provider value={appState}>
		{children}
	</AppStateContext.Provider>
}