import { GLOBAL_setAppState } from "../AppState";


export class Api {

	private static async setState(url: string) {
		// Rafrachit l'état du login en demandant à l'API
        // Doit mettre à jour le context React et re-render au besoin
        // TODO : contexte react avec re-render
        const response = await Api.get(url);
        const state = await response.json();
        GLOBAL_setAppState(state);
        localStorage.setItem("state", JSON.stringify(state));
	}

    static async retrieveState() {
        this.setState('auth/state')
    }

    static async refreshState() {
        this.setState('auth/refresh');
    }

    static get(path: string, init?: RequestInit): Promise<Response> {
        return fetch(Api.getPathPrefix() + path, {
			...init,
            'credentials': 'include',
        });
    }

    static post(path: string, body: object): Promise<Response> {
        return fetch(Api.getPathPrefix() + path, {
            'credentials': 'include', method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify(body),
        });
    }

	static submit(path: string, form: FormData) : Promise<Response> {
		return fetch(Api.getPathPrefix() + path, {
			'credentials': 'include', method: 'POST', body: form
		});
	}

	static upload(path: string, file: File) : Promise<Response> {
		const form = new FormData();
		form.append('file', file);
		return fetch(`/uploads/${path}`, {
			'credentials': 'include', method: 'POST', body: form
		});
	}

    private static getPathPrefix() {
        /*
        Pour changer l'API utilisée, par exemple pour dév séparément le front du back,
        changer cette entrée dans le localStorage de votre navigateur :
         localStorage.setItem("apiPrefix", "http://localhost:3000/api/") // Warning :  / final
         */
        return localStorage.getItem("apiPrefix") ?? "/api/";
    }
}